<template>
  <div class="card-group elevation-3">
    <slot />
  </div>
</template>

<script lang="ts">
export default {
  name: 'Card',
  props: {
    flex: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="sass">

.card-group
  padding: 24px (8 * $bw)

  + .card,
  + .card-group
    margin-top: 8 * $bw

  h3
    font: bold 1.75rem/1.2 $averta
    color: $c-text-alt

  .v-divider
    margin: (8 * $bw) 0

  .card__body
    padding: 0
    box-shadow: none !important
</style>
