import { useI18n } from 'nuxt-i18n-composable';
import Card from '@/components/Card.vue';
export default defineComponent({
  components: {
    Card: Card
  },
  setup: function setup() {
    var _userStore$attributes;
    var userStore = useUserStore();
    var emailNotificationLevel = ((_userStore$attributes = userStore.attributes.email_notification_level) === null || _userStore$attributes === void 0 ? void 0 : _userStore$attributes[0]) || 'all';
    var _useI18n = useI18n(),
      t = _useI18n.t;
    return {
      emailNotificationLevel: emailNotificationLevel,
      t: t
    };
  },
  data: function data() {
    return {
      allNotificationLevels: ['all', 'important', 'none']
    };
  },
  computed: {
    dynamicTranslations: function dynamicTranslations() {
      return {
        label: {
          all: this.t('profile.settings.mail_notifications.all.label'),
          important: this.t('profile.settings.mail_notifications.important.label'),
          none: this.t('profile.settings.mail_notifications.none.label')
        },
        infoText: {
          all: this.t('profile.settings.mail_notifications.all.info_text'),
          important: this.t('profile.settings.mail_notifications.important.info_text'),
          none: this.t('profile.settings.mail_notifications.none.info_text')
        }
      };
    }
  }
});