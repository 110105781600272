<template>
  <!-- disabled quality with tooltip -->
  <v-tooltip :disabled="disabled" color="info" top max-width="260" transition="slide-y-reverse-transition">
    <template #activator="{ on }">
      <div v-on="on">
        <slot />
      </div>
    </template>
    {{ $t('viewer.tooltip.restrict.text_strong') }}
    <br />
    <span class="light">{{ $t('viewer.tooltip.restrict.text') }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'TooltipWrapper',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
