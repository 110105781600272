import "core-js/modules/es.array.slice.js";
export default defineComponent({
  name: 'QualityRadioButton',
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    imageSrc: {
      type: String,
      required: true
    },
    imageType: {
      type: String,
      default: 'png'
    },
    text: {
      type: String,
      required: true
    }
  },
  methods: {
    capitalize: function capitalize(s) {
      return s.charAt(0).toUpperCase() + s.slice(1);
    }
  }
});